import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby";

import SolarContext from "../../contexts/solarContext";
import RadioCircle from "../../svgs/radioCircle";

const RadioIcon = RadioCircle;

const Next = "/solar/Questions/2";
const Points = [
  {
    question: "How much do you spend on electricity per month?",
    progress: "20%",
    options: [
      { answer: "Less than $400", to: Next, icon: <RadioIcon /> },
      { answer: "$400-$1000", to: Next, icon: <RadioIcon /> },
      { answer: "$1000-$2000", to: Next, icon: <RadioIcon /> },
      { answer: "$2000-$5000", to: Next, icon: <RadioIcon /> },
      { answer: "$5000-$10000", to: Next, icon: <RadioIcon /> },
      { answer: "More than $10000", to: Next, icon: <RadioIcon /> },
    ],
  },
];

export default function Question1({ title }) {
  const { que1, setQue1 } = useContext(SolarContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que1 === items.answer}
              onChange={(e) => {
                setQue1(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
