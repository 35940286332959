import React from "react";

export default function Field() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 128 128"
    >
      <g>
        <path d="M113.339,65.75H121.5a1.75,1.75,0,0,0,0-3.5H97.25V50.978a1.751,1.751,0,0,0-.615-1.332l-9.75-8.311a1.751,1.751,0,0,0-2.27,0l-9.75,8.311a1.751,1.751,0,0,0-.615,1.332V62.25H6.5a1.75,1.75,0,0,0,0,3.5h8.161L4.777,121.193A1.749,1.749,0,0,0,6.5,123.25h115a1.749,1.749,0,0,0,1.723-2.057ZM77.75,51.785l8-6.818,8,6.818V62.25H90.5V57a1.749,1.749,0,0,0-1.75-1.75h-6A1.749,1.749,0,0,0,81,57v5.25H77.75ZM84.5,62.25v-3.5H87v3.5Zm-1.872,3.5,4.126,54H73.92l-1.375-54Zm-37.256,0H55.455l-1.375,54H41.246Zm-27.156,0H28.263l-6.876,54H8.589Zm13.576,0H41.861l-4.126,54H24.916Zm25.789,54,1.375-54H69.044l1.375,54Zm32.683,0-4.125-54H96.208l6.308,49.54.568,4.46Zm16.349,0-6.308-49.54-.568-4.46h10.047l9.626,54Z" />
        <path d="M14.12,30.05h2.744a10.473,10.473,0,0,0,1.82,4.4l-1.932,1.933a1.75,1.75,0,0,0,2.476,2.474l1.931-1.931a10.448,10.448,0,0,0,4.391,1.82V41.48a1.75,1.75,0,0,0,3.5,0V38.746a10.465,10.465,0,0,0,4.4-1.82l1.932,1.931a1.749,1.749,0,1,0,2.474-2.474l-1.931-1.932a10.477,10.477,0,0,0,1.82-4.4H40.48a1.75,1.75,0,0,0,0-3.5H37.745a10.459,10.459,0,0,0-1.819-4.391l1.931-1.931a1.75,1.75,0,1,0-2.474-2.475l-1.932,1.932a10.472,10.472,0,0,0-4.4-1.821V15.12a1.75,1.75,0,0,0-3.5,0v2.745a10.459,10.459,0,0,0-4.391,1.819l-1.931-1.931a1.75,1.75,0,0,0-2.475,2.475l1.931,1.931a10.465,10.465,0,0,0-1.819,4.391H14.12a1.75,1.75,0,0,0,0,3.5Zm8.167-6.763A7.1,7.1,0,0,1,32.326,33.319l0,0,0,0a7.1,7.1,0,0,1-10.031,0l0,0h0a7.1,7.1,0,0,1,0-10.034Z" />
        <path d="M76.44,28.92H90.3a8.193,8.193,0,0,0,7.646-5.253h9.5a8.2,8.2,0,0,0,8.19-8.189v-.022a7.086,7.086,0,0,0-6.332-7.116A7.18,7.18,0,0,0,107.6,8.4,8.386,8.386,0,0,0,92.983,9.807a6.893,6.893,0,0,0-4.361,1.931,8.362,8.362,0,0,0-12.783,3.316A6.943,6.943,0,0,0,69.51,23.5,7.048,7.048,0,0,0,76.44,28.92ZM93.43,13.293a3.187,3.187,0,0,1,.518.046,1.754,1.754,0,0,0,1.98-1.326,4.885,4.885,0,0,1,9.225-.847,1.751,1.751,0,0,0,2.28.9,3.3,3.3,0,0,1,1.58-.237,3.565,3.565,0,0,1,3.127,3.628v.022a4.7,4.7,0,0,1-4.69,4.689H93.584a3.523,3.523,0,0,1-3.506-2.653,3.483,3.483,0,0,1,.252-2.269l.01-.023a3.473,3.473,0,0,1,.414-.649A3.428,3.428,0,0,1,93.43,13.293ZM73.6,19.823a3.453,3.453,0,0,1,3.22-1.231,1.754,1.754,0,0,0,1.959-1.331,4.865,4.865,0,0,1,8.007-2.5,6.906,6.906,0,0,0,6.794,8.905h.362A4.686,4.686,0,0,1,90.3,25.42H76.44a3.533,3.533,0,0,1-3.51-2.661A3.425,3.425,0,0,1,73.6,19.823Z" />
      </g>
    </svg>
  );
}
