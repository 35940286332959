import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby";

import SolarContext from "../../contexts/solarContext";
import Factory from "../../svgs/solar/factory";
import Field from "../../svgs/solar/field";
import Office from "../../svgs/solar/office";
import Other from "../../svgs/solar/other";

const Next = "/solar/Questions/5";
const Points = [
  {
    question: "Please select your proposed installation side",
    progress: "80%",
    options: [
      {
        answer: "Factory Roof",
        to: Next,
        icon: <Factory />,
      },
      { answer: "Office Roof", to: Next, icon: <Office /> },
      { answer: "Field", to: Next, icon: <Field /> },
      { answer: "Other", to: Next, icon: <Other /> },
    ],
  },
];
export default function Question4({ title }) {
  const { que4, setQue4 } = useContext(SolarContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que4 === items.answer}
              onChange={(e) => {
                setQue4(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
