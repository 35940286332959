import React from "react";

export default function Factory() {
  return (
    <svg
      id="Capa_1"
      enable-background="new 0 0 468.329 468.329"
      height="512"
      viewBox="0 0 468.329 468.329"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <g>
              <g>
                <path d="m280.577 436.21h-273.242c-4.063 0-7.335-3.29-7.335-7.335v-245.483c0-1.276.324-2.517.953-3.614 29.144-51.437 83.943-83.404 143.003-83.404 59.078 0 113.878 31.966 143.021 83.404.629 1.097.953 2.355.953 3.614v245.464c0 4.064-3.29 7.354-7.353 7.354zm-265.906-14.689h258.571v-236.151c-26.77-45.882-76.14-74.306-129.285-74.306s-102.516 28.424-129.286 74.306z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m422.501 217.786c-4.063 0-7.335-3.29-7.335-7.335v-89.337c-26.77-45.882-76.14-74.306-129.285-74.306-47.806 0-93.13 23.139-121.213 61.919-2.373 3.29-6.976 4.027-10.248 1.636-3.29-2.373-4.009-6.976-1.636-10.248 30.852-42.574 80.599-67.996 133.097-67.996 59.078 0 113.878 31.966 143.021 83.404.629 1.097.953 2.355.953 3.614v91.296c-.019 4.063-3.291 7.353-7.354 7.353z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m460.994 436.21h-180.417c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h173.063v-168.246c-19.849-33.674-56.202-54.512-95.323-54.512-26.842 0-52.732 9.744-72.904 27.436-3.056 2.679-7.695 2.373-10.356-.683-2.679-3.056-2.373-7.695.683-10.356 22.833-20.046 52.174-31.067 82.577-31.067 45.055 0 86.837 24.379 109.059 63.609.629 1.097.953 2.355.953 3.614v177.54c0 4.045-3.29 7.335-7.335 7.335z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m213.156 436.21h-138.418c-4.063 0-7.335-3.29-7.335-7.335v-172.093c0-4.063 3.29-7.335 7.335-7.335h138.419c4.063 0 7.335 3.29 7.335 7.335v172.093c.018 4.045-3.272 7.335-7.336 7.335zm-131.065-14.689h123.73v-157.404h-123.73z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m213.156 401.799h-138.418c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h138.419c4.063 0 7.335 3.29 7.335 7.335s-3.272 7.335-7.336 7.335z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m213.156 367.369h-138.418c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h138.419c4.063 0 7.335 3.29 7.335 7.335s-3.272 7.335-7.336 7.335z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m213.156 332.958h-138.418c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h138.419c4.063 0 7.335 3.29 7.335 7.335s-3.272 7.335-7.336 7.335z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m213.156 298.547h-138.418c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h138.419c4.063 0 7.335 3.29 7.335 7.335s-3.272 7.335-7.336 7.335z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m422.501 436.21h-103.324c-4.063 0-7.335-3.29-7.335-7.335v-103.252c0-4.063 3.29-7.335 7.335-7.335h103.324c4.063 0 7.335 3.29 7.335 7.335v103.252c0 4.045-3.272 7.335-7.335 7.335zm-95.989-14.689h88.635v-88.563h-88.635z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m422.501 401.799h-103.324c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h103.324c4.063 0 7.335 3.29 7.335 7.335s-3.272 7.335-7.335 7.335z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path d="m422.501 367.369h-103.324c-4.063 0-7.335-3.29-7.335-7.335s3.29-7.335 7.335-7.335h103.324c4.063 0 7.335 3.29 7.335 7.335s-3.272 7.335-7.335 7.335z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
