import React, { useState } from "react";
import { Router } from "@reach/router";
import {
  PrivacySection,
  QuoteLayout,
  Thankyou,
} from "../../components/utility/quote";
import { Product_Quote_Routes } from "../../data/Routes";
import SolarContext from "../../contexts/solarContext";
import { ServicesMenuSection } from "../../components/containers/ServicesMenuSection";
import { Footer } from "../../components/utility";
import Question1 from "./Question1";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Question4 from "./Question4";
import Form from "./Form";
import firebase from "gatsby-plugin-firebase";
import GtagCode from "../../data/GtagCode";

const Quote = () => {
  const [que1, setQue1] = useState(null);
  const [que2, setQue2] = useState(null);
  const [que3, setQue3] = useState(null);
  const [que4, setQue4] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const addLeadToBackend = async (values) => {
    await fetch("/.netlify/functions/sendgrid", {
      method: "POST",
      body: JSON.stringify({
        email: "hitesh.patel@icthub.com.au",
        subject: "New lead recived!!",
        name: values.fullname,
      }),
    });
    var timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.firestore().collection("Business_Solar").add({
      customerDetails: values,
      "How much do you spend on electricity per month??": que1,
      "What is your time frame for getting solar?": que2,
      "Are you looking to pay cash or monthly repayments?": que3,
      "Please select your proposed installation side": que4,
      Lead_Generated: timestamp,
    });
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
  };

  return (
    <QuoteLayout>
      <GtagCode />
      <SolarContext.Provider
        value={{
          que1,
          setQue1,
          que2,
          setQue2,
          que3,
          setQue3,
          que4,
          setQue4,
          formValues,
          setFormValues,
          addLeadToBackend,
        }}
      >
        <Router>
          <section path={Product_Quote_Routes.BusinessSolar}>
            <Question1 title="Business Solar" path="/" />
            <Question2 title="Business Solar" path="/2" />
            <Question3 title="Business Solar" path="/3" />
            <Question4 title="Business Solar" path="/4" />
            <Form title="Business Solar" path="/5" />
            <Thankyou title="Business Solar" path="/thankyou" />
          </section>
        </Router>
      </SolarContext.Provider>
      <PrivacySection />
      <ServicesMenuSection />
      <Footer />
    </QuoteLayout>
  );
};

export default Quote;
