import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby";

import SolarContext from "../../contexts/solarContext";

import Cash from "../../svgs/misc/cash";
import Bothoptions from "../../svgs/misc/bothoptions";
import Reapayment from "../../svgs/misc/repayment";

const Next = "/solar/Questions/4";
const Points = [
  {
    question: "Are you looking to pay cash or monthly repayments?",
    progress: "60%",
    options: [
      {
        answer: "Cash",
        to: Next,
        icon: <Cash />,
      },
      {
        answer: "Monthly repayments ($0 down)",
        to: Next,
        icon: <Reapayment />,
      },
      { answer: "Show me options for both", to: Next, icon: <Bothoptions /> },
    ],
  },
];
export default function Question3({ title }) {
  const { que3, setQue3 } = useContext(SolarContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que3 === items.answer}
              onChange={(e) => {
                setQue3(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
